export var colors = {
  gray5: "#CBCFD7",
  dark: "#222",
  light: "#b3b3b3",
  middleGrey: "#f5f5f5",
  gray6: "#EDF0F7",
  gray7: "#EDF0F6",
  lighterGrey: "#757575",
  gray3: "#7A8398",
  gray2: "#37445F",
  gray1: "#0E1524",
  lightGrey: "#EDF0F7",
  darkGrey: "#37445F",
  white: "#FFFFFF",
  blue1: "#2C6AFA",
  primaryLight: "#7697FF",
  textDark: "#212121",
  textDark2: "#21212199",
  black: "#000000",
  successMain: "#4CAF50",
  warning: "#EBA638",
  grayBlue: "#9FB7CC",
  grayBlueLight: "#CDDEED",
  red: "#f44336",
  orange: "#ED6C02",
  mediumGray: "#989898"
};
export var palette = {
  primary: {
    light: "#5b73d6",
    main: "#0e1524"
  },
  secondary: {
    light: "#72bafd",
    main: "#2c6afa",
    dark: "#0040c6"
  },
  warning: {
    main: "#f2994a"
  },
  info: {
    main: "#F2C94C"
  },
  success: {
    main: "#18CC55"
  }
};