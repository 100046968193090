import { BrowserTracing } from "@sentry/tracing";
import { ExtraErrorData } from "@sentry/integrations";
import { Integration, Options } from "@sentry/types";

interface Identifiers {
  dsn: string;
  env: string;
  app: string;
}

export const getSentryConfig = (
  identifiers: Identifiers,
  customOptions?: Options,
) => {
  const defaultValues = {
    dsn: identifiers.dsn,
    environment: identifiers.env,
    debug: false,
    integrations: [
      new BrowserTracing(),
      new ExtraErrorData({ depth: 10 }) as unknown as Integration,
    ],
    tracesSampleRate: 0,
    initialScope: {
      tags: { web: identifiers.app },
    },
    normalizeDepth: 11,
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
    ],
  };
  return {
    ...defaultValues,
    ...customOptions,
  };
};
