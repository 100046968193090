import styled from "styled-components";
export var spacing = ["4px",
//0
"8px",
//1
"12px",
//2
"16px",
//3
"20px",
//4
"24px",
//5
"32px",
//6
"40px",
//7
"64px",
//8
"96px",
//9
"128px",
//10
"256px",
//11
"512px",
//12
"768px" //13
];

export var FormContainer = styled.div.withConfig({
  displayName: "indexstyle__FormContainer",
  componentId: "sc-oemkyr-0"
})(["padding:0 ", " ", " ", ";"], spacing[7], spacing[8], spacing[7]);
export var Form = styled.form.withConfig({
  displayName: "indexstyle__Form",
  componentId: "sc-oemkyr-1"
})(["max-width:400px;margin:0 auto;display:flex;flex-direction:column;background:white;padding:", ";box-shadow:rgba(0,0,0,0.2) 0 0 10px;border-collapse:collapse;"], spacing[8]);
export var TextFieldContainer = styled.div.withConfig({
  displayName: "indexstyle__TextFieldContainer",
  componentId: "sc-oemkyr-2"
})(["margin-bottom:", ";padding-top:", ";"], spacing[2], spacing[3]);
export var ButtonContainer = styled.div.withConfig({
  displayName: "indexstyle__ButtonContainer",
  componentId: "sc-oemkyr-3"
})(["padding:", " 0;text-align:center;"], spacing[4]);