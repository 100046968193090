import _defineProperty from "/home/circleci/project/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { Auth } from "../auth";
import { createUploadLink } from "apollo-upload-client";
import { apolloErrorLink } from "@fsl-admin/shared/utils/apolloErrorLink";
import Cookies from "js-cookie";
import { AUTH_COOKIE, GOOGLE_PROFILE_DATA, JS_COOKIE_OPTIONS, REFRESH_COOKIE } from "@fsl-admin/shared/constants/cookies";
import { RetryLink } from "@apollo/client/link/retry";
var retryLink = new RetryLink();
var uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL
});
var authMiddleware = new ApolloLink(function (operation, forward) {
  operation.setContext(function (_ref) {
    var _ref$headers = _ref.headers,
      headers = _ref$headers === void 0 ? {} : _ref$headers;
    return {
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        authorization: "Bearer " + Auth.getToken()
      })
    };
  });
  return forward(operation);
});
var storeTokens = function storeTokens(_ref2) {
  var accessToken = _ref2.accessToken,
    refreshToken = _ref2.refreshToken;
  Cookies.set(AUTH_COOKIE, accessToken, JS_COOKIE_OPTIONS);
  Cookies.set(REFRESH_COOKIE, refreshToken, JS_COOKIE_OPTIONS);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  Auth.setUser(_objectSpread(_objectSpread({}, Auth.getUser()), {}, {
    apiToken: accessToken,
    refreshToken: refreshToken
  }));
};
var handleRefreshTokenError = function handleRefreshTokenError() {
  Cookies.remove(AUTH_COOKIE);
  Cookies.remove(REFRESH_COOKIE);
  Cookies.remove(GOOGLE_PROFILE_DATA);
  Auth.setUser(undefined);
};
export var apolloClient = new ApolloClient({
  // Because the type contracts aren't nominally equivalent between
  // the official Apollo Client and the object created by createUploadLink
  // (at the moment), we need to use @ts-ignore to prevent type error.
  // https://www.apollographql.com/blog/graphql/file-uploads/with-react-hooks-typescript-amazon-s3-tutorial/
  link: from([apolloErrorLink(function () {
    return Auth.getRefreshToken();
  }, storeTokens, handleRefreshTokenError), retryLink, authMiddleware, uploadLink]),
  uri: process.env.NEXT_PUBLIC_API_URL,
  cache: new InMemoryCache()
});