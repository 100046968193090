const MAX_AGE = 60 * 60 * 24 * 30; // 30 days

export const AUTH_COOKIE = "_auth";
export const REFRESH_COOKIE = "_refr";
export const ADMIN_AUTH_COOKIE = "_aauth";
export const GOOGLE_PROFILE_DATA = "_gpd";
export const ADMIN_REFRESH_COOKIE = "_arefr";
export const COOKIE_BANNER_ACCEPTED_COOKIE = "_cb_acc";
export const COOKIE_BANNER_RESPONSE = "_cb_resp";
export const COOKIE_PUBLIC_PROFILE = "_cpp";

export const COOKIE_OPTIONS = {
  expires: new Date(Date.now() + MAX_AGE * 1000),
  secure: process.env.NODE_ENV === "production",
  maxAge: MAX_AGE,
  // httpOnly: true,
  sameSite: true,
  path: "/",
};

export const JS_COOKIE_OPTIONS: {
  expires: Date;
  secure: boolean;
  sameSite: "strict";
  path: string;
} = {
  expires: new Date(Date.now() + MAX_AGE * 1000),
  secure: process.env.NODE_ENV === "production",
  // httpOnly: true,
  sameSite: "strict",
  path: "/",
};
