export interface Section {
  id: string;
  name: string;
  route: string;
  defaultQueryParams: string;
  order: number;
}

export enum SectionIds {
  TeamMembers = "1",
  Skills = "2",
  Clients = "3",
  Projects = "4",
  MatchingTeamMembers = "5",
  MatchingDeals = "6",
  Allocations = "7",
  Users = "8",
  Roles = "9",
  Deals = "10",
  Feedback = "11",
  Contacts = "12",
  Analytics = "13",
  AAF = "C-1",
  TeamMemberProfile = "C-2",
}

export const PermissionScopes = {
  Assigned: "Assigned",
  NonBillable: "NonBillable",
  Billable: "Billable",
  Pipeline: "Pipeline",
  Leadership: "Leadership",
  All: "All",
};

export const PermissionDataTypes = {
  NonSensitive: "NonSensitive",
  Sensitive: "Sensitive",
  Confidential: "Confidential",
  All: "All",
};

export const SectionPermissions = {
  VIEW: "View",
  EDIT: "ViewEdit",
  VIEW_EDIT: "ViewEdit",
  ALL: "All",
};

export const SectionPermissionValues = {
  ALLOW: "Allow",
  DENY: "Deny",
  DENY_EDIT: "DenyEdit",
};

export enum AccessTypes {
  NoAccess = "No Access",
  PartialAccess = "Partial Access",
  FullAccess = "Full Access",
}
