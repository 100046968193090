import * as Sentry from "@sentry/browser";
import { Breadcrumb, CaptureContext } from "@sentry/types";
import { _get } from "@fsl-admin/shared/utils";

export const sendCustomEvent = (
  sentry: typeof Sentry,
  message: string,
  captureContext: CaptureContext,
  error: unknown,
  breadcrumbs: Breadcrumb[] = [],
) => {
  switch (_get(error, "code", "")) {
    case "ECONNABORTED":
      break;
    default: {
      for (let i = 0; i < breadcrumbs.length; i++) {
        Sentry.addBreadcrumb(breadcrumbs[i]);
      }
      Sentry.captureMessage(message, captureContext);
      break;
    }
  }
};
