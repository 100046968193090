export var palette = {
  primary: {
    light: "#5b73d6",
    main: "#0e1524",
    dark: "#002274"
  },
  secondary: {
    light: "#7697FF",
    main: "#2c6afa",
    dark: "#005d99"
  },
  error: {
    light: "#e94948",
    main: "#b00020",
    dark: "#790000"
  },
  warning: {
    light: "#ffb74d",
    main: "#ff9800",
    dark: "#f57c00"
  },
  info: {
    light: "#64b5f6",
    main: "#2196f3",
    dark: "#1976d2"
  },
  success: {
    light: "#81c784",
    main: "#4caf50",
    dark: "#388e3c"
  }
};