import { SecurityRolePermission } from "../interfaces/SecurityRolePermission";

export const PermissionDataTypes = {
  NonSensitive: "NonSensitive",
  Sensitive: "Sensitive",
  Confidential: "Confidential",
  All: "All",
};

export const PermissionScopes = {
  Assigned: "Assigned",
  NonBillable: "NonBillable",
  Billable: "Billable",
  Pipeline: "Pipeline",
  Leadership: "Leadership",
  All: "All",
};

export function getAllPermissionScopes() {
  // Add every single permission
  return Object.values(PermissionScopes).filter(
    (value: string) => value !== PermissionScopes.All,
  );
}

export function getAllPermissionInfoTypes() {
  return Object.values(PermissionDataTypes).filter(
    (value: string) => value !== PermissionDataTypes.All,
  );
}

export function getAllPermissionsForSection<T extends SecurityRolePermission>(
  permission: T,
  infoTypes: string[],
  scopes: string[],
) {
  const permissions: T[] = [];

  // Add every single permission
  scopes.forEach((scope: string) => {
    infoTypes.forEach((infoType: string) => {
      permissions.push({
        ...permission,
        infoType: infoType,
        scope: scope,
      });
    });
  });

  return permissions;
}

export function breakDownPermissions<T extends SecurityRolePermission>(
  permissionList: T[],
): T[] {
  // Get all not "All" permissions
  const allInfoTypes = getAllPermissionInfoTypes();
  const allScopes = getAllPermissionScopes();

  return permissionList.flatMap((permission) =>
    getAllPermissionsForSection(
      permission,
      permission.infoType === PermissionDataTypes.All
        ? allInfoTypes
        : [permission.infoType],
      permission.scope === PermissionScopes.All
        ? allScopes
        : [permission.scope],
    ),
  );
}
