import styled from "styled-components";
export var Container = styled.div.withConfig({
  displayName: "indexstyle__Container",
  componentId: "sc-1ke4ljf-0"
})(["display:flex;"]);
export var Body = styled.div.withConfig({
  displayName: "indexstyle__Body",
  componentId: "sc-1ke4ljf-1"
})(["width:100%;flex-grow:1;overflow:auto;"]);
export var Content = styled.div.withConfig({
  displayName: "indexstyle__Content",
  componentId: "sc-1ke4ljf-2"
})(["margin-left:", "px;transition:", ";"], function (_ref) {
  var isOpen = _ref.isOpen,
    theme = _ref.theme;
  return isOpen ? theme === null || theme === void 0 ? void 0 : theme.sidebar.openWidth : theme === null || theme === void 0 ? void 0 : theme.sidebar.closedWidth;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme === null || theme === void 0 ? void 0 : theme.transitions.create("margin-left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  });
});