var user = undefined;
export var Auth = {
  getUser: function getUser() {
    return user;
  },
  getRoles: function getRoles() {
    return user && user.roles;
  },
  getPermissions: function getPermissions() {
    return user && user.permissions;
  },
  getRoutePermissions: function getRoutePermissions() {
    return user && user.routePermissions;
  },
  getToken: function getToken() {
    return user && user.apiToken;
  },
  getRefreshToken: function getRefreshToken() {
    return user && user.refreshToken;
  },
  setUser: function setUser(newUser) {
    user = newUser;
  }
};