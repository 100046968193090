import axios from "axios";
import { onError } from "@apollo/client/link/error";
import { fromPromise } from "@apollo/client/core";
import * as Sentry from "@sentry/browser";
import { Severity } from "@sentry/browser";
import { sendCustomEvent } from "../sentry/ui";

export const apolloErrorLink = (
  getRefreshToken: () => string | undefined,
  setTokens: ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) => void,
  onRefreshTokenError: () => void,
  identify?: (id: string, payload: unknown) => void,
  sentry?: typeof Sentry,
) => {
  let isRefreshing = false;
  let pendingRequests: (() => void)[] = [];

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
  };

  const getNewTokens = async () => {
    const data = await axios
      .post(`${process.env.NEXT_PUBLIC_AUTH_API_URL}/auth/refresh_token`, {
        refreshToken: getRefreshToken(),
      })
      .then((resp) => resp.data);
    return data;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return onError(({ graphQLErrors, operation, forward, networkError }) => {
    if (networkError) {
      const breadcrumbs = [
        {
          category: "error_network_info",
          message: `Error Message: ${networkError?.message}`,
          level: Severity.Info,
          data: networkError,
        },
      ];
      const context = {
        level: Severity.Error,
        extra: {
          error: networkError,
        },
      };
      if (sentry)
        sendCustomEvent(
          sentry,
          "Graphql Network Error",
          context,
          networkError,
          breadcrumbs,
        );
    }
    if (graphQLErrors)
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case "UNAUTHENTICATED": {
            let forward$;
            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                getNewTokens()
                  .then(({ accessToken, refreshToken }) => {
                    setTokens({ accessToken, refreshToken });
                    identify?.("", {
                      token: accessToken,
                    });
                    return true;
                  })
                  .catch(() => {
                    onRefreshTokenError();
                    return true;
                  })
                  .finally(() => {
                    resolvePendingRequests();
                    isRefreshing = false;
                  }),
              ).filter((value) => Boolean(value));
            } else {
              forward$ = fromPromise(
                new Promise<void>((resolve) => {
                  pendingRequests.push(() => resolve());
                }).catch((error) => {
                  Sentry.captureException(error);
                }),
              );
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
      }
  });
};
