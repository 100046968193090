import { useSnackbar, VariantType } from "notistack";
import { useCallback } from "react";
export const useCustomSnackbar = () => {
  const { enqueueSnackbar: _enqueueSnackbar } = useSnackbar();

  const enqueueSnackbar = useCallback(
    (message: string, variant: VariantType = "success") => {
      _enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        variant,
        preventDuplicate: true,
      });
    },
    [],
  );

  return { enqueueSnackbar };
};
