export var typography = {
  h1: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "96px",
    lineHeight: "127px",
    letterSpacing: "-1.5px"
  },
  h2: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "60px",
    lineHeight: "71px",
    letterSpacing: "-0.5px"
  },
  h3: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "48px",
    lineHeight: "57px"
  },
  h4: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: "bolder",
    fontSize: "34px",
    lineHeight: "40px",
    letterSpacing: "0.35px"
  },
  h5: {
    fontFamily: "'Work Sans', sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px"
  },
  h6: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px"
  },
  subtitle1: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.15px"
  },
  subtitle2: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.1px"
  },
  body1: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0.44px"
  },
  body2: {
    fontFamily: "'Nunito', sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.25px"
  },
  button: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "1.25px",
    textTransform: "uppercase"
  },
  caption: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px"
  },
  overline: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase"
  }
};