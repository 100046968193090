import { createTheme } from "@material-ui/core/styles";
var palette = {
  primary: {
    light: "#5b73d6",
    main: "#2C6AFA"
  },
  info: {
    main: "#2196F3"
  },
  secondary: {
    main: "#2C6AFA"
  },
  warning: {
    main: "#ED6C02"
  },
  success: {
    main: "#4CAF50"
  },
  text: {
    primary: "#000000DE"
  }
};
export var newTheme = createTheme({
  palette: palette,
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    h5: {
      fontSize: "24px",
      fontWeight: 500
    }
  },
  overrides: {
    MuiChip: {
      root: {
        height: 24,
        maxWidth: "100%"
      },
      label: {
        fontSize: 13,
        fontWeight: 400,
        lineHeight: "18px",
        paddingLeft: 10,
        paddingRight: 10
      },
      deleteIcon: {
        width: 16,
        height: 16
      }
    },
    MuiDivider: {
      root: {
        border: "1px solid rgba(0,0,0,0.12)",
        boxSizing: "border-box",
        backgroundColor: "transparent"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none",
        padding: "5px 10px"
      }
    },
    MuiDialogTitle: {
      root: {
        "& h2": {
          fontSize: "1.25rem !important",
          fontFamily: "'Work Sans', sans-serif !important",
          fontWeight: "500 !important",
          lineHeight: "1.6 !important"
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)"
      }
    },
    MuiSelect: {
      root: {
        padding: "8px 12px",
        lineHeight: "24px"
      },
      selectMenu: {
        minHeight: "40px",
        boxSizing: "border-box"
      }
    },
    MuiOutlinedInput: {
      root: {
        color: "rgba(0, 0, 0, 0.6)"
      },
      adornedStart: {
        paddingLeft: 10
      },
      input: {
        boxSizing: "border-box",
        fontSize: "13px",
        minHeight: "40px"
      },
      notchedOutline: {
        borderColor: "rgba(14, 21, 36, 0.08);"
      }
    },
    MuiSlider: {
      root: {
        width: "auto !important"
      },
      valueLabel: {
        top: -13,
        "& *": {
          background: "transparent",
          color: "#000"
        }
      }
    },
    MuiDialogContent: {
      root: {
        "&:first-child": {
          paddingTop: 8
        }
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 4,
        "&:hover": {
          background: palette.primary.main
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 28,
        width: 16
      }
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
        color: "rgba(255, 255, 255, 0.85)"
      },
      root: {
        marginTop: 0.5,
        marginBottom: 0.5
      }
    }
  }
});