import { breakDownPermissions } from "../utils/permission-utils";
import {
  Permission,
  SectionRoutePermission,
  UserSecurityRole,
} from "../interfaces/UserSecurityRole";
import { SecurityRolePermissionWithSection } from "../interfaces/SecurityRolePermission";
import { SectionPermissions, SectionPermissionValues } from "./sections";

export const getRolesAndPermissions = (userProfile: any) => {
  const userRoles: string[] = [];
  let userPermissions: Permission[] = [];
  const userRoutePermissions: SectionRoutePermission[] = [];

  const userRolePermissions: Permission[] = [];
  userProfile?.securityRoles.forEach(
    (securityRole: { role: UserSecurityRole }) => {
      // Get Every Role
      userRoles.push(securityRole.role.name);
      // Get every permission on the role
      if (!securityRole.role?.securityRolesPermissions) return;

      const permissions = securityRole.role.securityRolesPermissions;
      permissions.forEach((permission) => {
        userRolePermissions.push({
          section: permission.section.id,
          sectionName: permission.section.name || "",
          action: permission.action,
          infoType: permission.infoType,
          scope: permission.scope,
          value: permission.value,
        });
      });

      const routePermissions = securityRole.role.securityRouteRolePermission;
      routePermissions.forEach((route: SectionRoutePermission) => {
        userRoutePermissions.push({
          id: route.id,
          roleId: route.roleId,
          sectionRouteId: route.sectionRouteId,
          sectionRoute: route.sectionRoute,
          checked: route.checked,
        });
      });
    },
  );
  userPermissions = breakDownPermissions(userRolePermissions);

  const userOnlyPermissions: Permission[] = [];
  userProfile?.userPermissions?.forEach(
    (permission: SecurityRolePermissionWithSection) => {
      if (!permission?.sectionId) return;

      userOnlyPermissions.push({
        section: permission.sectionId,
        sectionName: permission?.sectionName || permission?.section?.name || "",
        action: permission.action,
        infoType: permission.infoType,
        scope: permission.scope,
        value: permission.value,
      });
    },
  );
  const userOnlyPermissionsSeparated =
    breakDownPermissions(userOnlyPermissions);

  userOnlyPermissionsSeparated.forEach((permission: Permission) => {
    if (
      permission.value === SectionPermissionValues.DENY ||
      permission.value === SectionPermissionValues.DENY_EDIT
    ) {
      const indexPermission = userPermissions.findIndex(
        (value) =>
          value.section === permission.section &&
          value.infoType === permission.infoType &&
          value.scope === permission.scope,
      );
      if (indexPermission > -1) {
        if (
          permission.action === SectionPermissions.VIEW ||
          ((permission.action === SectionPermissions.EDIT ||
            permission.action === SectionPermissions.VIEW_EDIT) &&
            userPermissions[indexPermission].action === SectionPermissions.EDIT)
        ) {
          userPermissions.splice(indexPermission, 1);
        } else if (
          (permission.action === SectionPermissions.EDIT ||
            permission.action === SectionPermissions.VIEW_EDIT) &&
          userPermissions[indexPermission].action ===
            SectionPermissions.VIEW_EDIT
        ) {
          userPermissions[indexPermission].action = SectionPermissions.VIEW;
        }
      }
    } else {
      userPermissions.push(permission);
    }
  });

  userProfile?.userRoutePermissions?.forEach(
    (route: SectionRoutePermission) => {
      if (route.denied) {
        const routeIndex = userRoutePermissions.findIndex(
          (sectionRoute: SectionRoutePermission) =>
            sectionRoute.sectionRouteId === route.sectionRouteId &&
            sectionRoute.sectionRoute.sectionId ===
              route.sectionRoute.sectionId &&
            sectionRoute.sectionRoute.key === route.sectionRoute.key,
        );
        if (routeIndex > -1) userRoutePermissions.splice(routeIndex, 1);
      } else {
        userRoutePermissions.push({
          id: route.id,
          roleId: route.roleId,
          sectionRouteId: route.sectionRouteId,
          sectionRoute: route.sectionRoute,
          checked: route.checked,
        });
      }
    },
  );

  return {
    userRoles,
    userPermissions,
    userRoutePermissions,
  };
};
