import styled from "styled-components";
import { Drawer as MuiDrawer, ListItem as MuiListItem, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText, Avatar as MuiAvatar } from "@material-ui/core";
import { colors } from "../../../constants/colors";
export var Drawer = styled(MuiDrawer).withConfig({
  displayName: "indexstyle__Drawer",
  componentId: "sc-1rhi08b-0"
})([".MuiDivider-root{background-color:", ";}.MuiDrawer-paper{flex-shrink:0;overflow:hidden;color:", ";background-color:", ";transition:", ";", "{width:", "px;}.MuiList-root{overflow-y:auto;overflow-x:hidden;}}"], function (_ref) {
  var theme = _ref.theme;
  return theme.sidebar.dividerColor;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.palette.primary.contrastText;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.palette.primary.main;
}, function (_ref4) {
  var theme = _ref4.theme,
    open = _ref4.open;
  return open ? theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }) : theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  });
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.breakpoints.up("sm");
}, function (_ref6) {
  var theme = _ref6.theme,
    open = _ref6.open;
  return open ? theme.sidebar.openWidth : theme.sidebar.closedWidth;
});
export var OpenButton = styled.div.withConfig({
  displayName: "indexstyle__OpenButton",
  componentId: "sc-1rhi08b-1"
})(["cursor:pointer;height:32px;width:32px;background-color:", ";border-radius:16px;left:calc( ", "px - 16px );top:24px;position:fixed;transition:", ";box-shadow:0px 3px 5px -2px rgb(0 0 0 / 50%);"], function (_ref7) {
  var theme = _ref7.theme;
  return theme.palette.secondary.main;
}, function (_ref8) {
  var isOpen = _ref8.open,
    theme = _ref8.theme;
  return isOpen ? theme.sidebar.openWidth : theme.sidebar.closedWidth;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.transitions.create("left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  });
});
export var LogoContainer = styled.div.withConfig({
  displayName: "indexstyle__LogoContainer",
  componentId: "sc-1rhi08b-2"
})(["margin-left:", ";margin-right:", ";margin-bottom:-11px;margin-top:24px;align-self:center;"], function (_ref10) {
  var isOpen = _ref10.open;
  return isOpen ? "10px" : "auto";
}, function (_ref11) {
  var isOpen = _ref11.open;
  return isOpen ? "0" : "auto";
});
export var Avatar = styled(MuiAvatar).withConfig({
  displayName: "indexstyle__Avatar",
  componentId: "sc-1rhi08b-3"
})(["width:", "px;height:", "px;"], function (_ref12) {
  var theme = _ref12.theme;
  return theme.spacing(7);
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.spacing(7);
});
export var AvatarContainer = styled.div.withConfig({
  displayName: "indexstyle__AvatarContainer",
  componentId: "sc-1rhi08b-4"
})(["margin-top:", "px;margin-bottom:", "px;display:flex;justify-content:center;align-items:center;flex-direction:column;"], function (_ref14) {
  var theme = _ref14.theme;
  return theme.spacing(1);
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.spacing(2);
});
export var ListGroup = styled.div.withConfig({
  displayName: "indexstyle__ListGroup",
  componentId: "sc-1rhi08b-5"
})(["cursor:pointer;padding:5px 10px 5px 15px;"]);
export var ListGroupTitle = styled.div.withConfig({
  displayName: "indexstyle__ListGroupTitle",
  componentId: "sc-1rhi08b-6"
})(["cursor:pointer;color:", ";padding-bottom:5px;"], colors.light);
export var ListGroupSeparator = styled.div.withConfig({
  displayName: "indexstyle__ListGroupSeparator",
  componentId: "sc-1rhi08b-7"
})(["width:100%;height:1px;background-color:", ";opacity:0.25;margin:5px 0;"], colors.light);
export var ListItem = styled(MuiListItem).withConfig({
  displayName: "indexstyle__ListItem",
  componentId: "sc-1rhi08b-8"
})(["height:2.8em;color:", ";padding:0 5px;border-radius:5px;margin:3px 0;&:hover{color:", ";background-color:", ";}&.Mui-selected,&.Mui-selected:hover{color:", ";background-color:", ";}"], colors.light, colors.white, function (_ref16) {
  var theme = _ref16.theme;
  return theme.palette.secondary.dark;
}, colors.white, function (_ref17) {
  var theme = _ref17.theme;
  return theme.palette.secondary.main;
});
export var ListItemIcon = styled(MuiListItemIcon).withConfig({
  displayName: "indexstyle__ListItemIcon",
  componentId: "sc-1rhi08b-9"
})(["padding-left:", ";padding-right:", ";"], function (_ref18) {
  var isOpen = _ref18.open;
  return isOpen ? "5px" : "10px";
}, function (_ref19) {
  var isOpen = _ref19.open;
  return isOpen ? "5px" : "10px";
});
export var ListItemText = styled(MuiListItemText).withConfig({
  displayName: "indexstyle__ListItemText",
  componentId: "sc-1rhi08b-10"
})(["span{font-family:\"Red Hat Display\",sans-serif;}"]);
export var ListParent = styled.div.withConfig({
  displayName: "indexstyle__ListParent",
  componentId: "sc-1rhi08b-11"
})(["height:2.8em;padding:0 5px;border-radius:5px;margin:3px 0;cursor:pointer;background:\"#37445F\";color:", ";&:hover{color:", ";background-color:", ";}color:", ";background-color:", ";"], colors.light, colors.white, function (_ref20) {
  var theme = _ref20.theme;
  return theme.palette.secondary.dark;
}, function (_ref21) {
  var isSelected = _ref21.selected;
  return isSelected ? colors.white : "inherited";
}, function (_ref22) {
  var isSelected = _ref22.selected,
    theme = _ref22.theme;
  return isSelected ? theme.palette.primary.dark : "none";
});
export var ListParentItem = styled(MuiListItem).withConfig({
  displayName: "indexstyle__ListParentItem",
  componentId: "sc-1rhi08b-12"
})(["height:2em;color:", ";padding:0 5px;border-radius:5px;margin:3px 0;&:hover{color:", ";background-color:", ";}&.Mui-selected,&.Mui-selected:hover{color:", ";background-color:", ";}"], colors.light, colors.white, function (_ref23) {
  var theme = _ref23.theme;
  return "transparent";
}, colors.white, function (_ref24) {
  var theme = _ref24.theme;
  return "transparent";
});
export var ListParentItemIcon = styled(MuiListItemIcon).withConfig({
  displayName: "indexstyle__ListParentItemIcon",
  componentId: "sc-1rhi08b-13"
})(["padding-left:", ";padding-right:", ";"], function (_ref25) {
  var isOpen = _ref25.open;
  return isOpen ? "0" : "15px";
}, function (_ref26) {
  var isOpen = _ref26.open;
  return isOpen ? "0" : "15px";
});
export var Chevron = styled.div.withConfig({
  displayName: "indexstyle__Chevron",
  componentId: "sc-1rhi08b-14"
})(["height:10px;width:10px;border-right:3px solid white;border-bottom:3px solid white;opacity:0.6;margin-top:11px;margin-left:", ";transform:rotate(", "deg);transition:", ";"], function (_ref27) {
  var isOpen = _ref27.open;
  return isOpen ? "12px" : "10px";
}, function (_ref28) {
  var isOpen = _ref28.open;
  return isOpen ? 135 : 315;
}, function (_ref29) {
  var theme = _ref29.theme;
  return theme.transitions.create("transform", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  });
});