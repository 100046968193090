import { Chip, Theme } from "@material-ui/core";
import styled from "styled-components";
import { hexToRgb } from "@fsl-admin/shared/utils/style-utils";

export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface HeaderProps {
  bg_color?: string;
  text_color?: string;
}

export const ColorChip = styled(Chip)<HeaderProps>`
  ${({ text_color }) => `
    > span {
      color: ${text_color ? text_color : "black"};
    }
    background-color: RGBA(
      ${text_color ? hexToRgb(text_color)?.r : "0"},
      ${text_color ? hexToRgb(text_color)?.g : "0"},
      ${text_color ? hexToRgb(text_color)?.b : "0"},
      ${!text_color ? "0.05" : "0.1"}
    );
    border: none;
  `}
`;

export const Img = styled.img`
  font-size: 0;
`;

export const BlueSpan = styled.span`
  font-weight: 500;
  color: #2c6afa;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  ${({ theme }: { theme: Theme }) => [theme.breakpoints.only("xs")]} {
    overflow: auto;
  }
`;

type PageContainerProps = {
  customWidth?: string | number;
};

export const PageContainer = styled.div<PageContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ customWidth }) => `
    width: ${customWidth || "100vw"};
  `}})}
  width: 100vw;
  min-height: 100vh;
`;

interface HeaderProps {
  "justify-content"?: string;
}

export const Header = styled.div<HeaderProps>`
  width: 100%;
  padding: 32px 24px;
  display: flex;
  ${({ "justify-content": justifyContent }) => `
    justify-content: ${justifyContent ?? "space-between"};
  `}
  align-items: center;
  ${({ theme }: { theme: Theme }) => [theme.breakpoints.up("sm")]} {
    pagging: 32px;
  }
`;
